.auth-main-wrapper{
    background-color: #edeff0;
    overflow-y: scroll !important;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh);
  }


  .sign-up-page-flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
  }
  
  .left-column, .right-column {
    width: 50%;
    padding: 40px 25px;
  }
  
  .left-column {
    background: #181636;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    padding: 20px 35px 20px !important;
  }
  
  .right-column {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    border-radius: 0px 5px 5px 0px;
  }
  
  .right-column-wrapper {
    padding: 0px 10px;
    width: 100%;
  }
  
  .welcome-text {
    text-align: left;
  }
  
  .forget-text{
    margin-bottom: 20px;
  }
  
  .welcome-text h3 {
    margin: 0 0 10px 0;
    font-weight: 500;
    font-size: 30px;
    color: #3e416d;
    text-transform: capitalize;
  }
  
  .forget-text h4 {
    margin: 0 0 15px 0;
    font-weight: 600;
    font-size: 20px;
    color: #3e416d;
    text-transform: capitalize;
  }
  
  .welcome-text p {
    font-weight: 400;
    font-size: 15px;
    color: #666666;
    margin-bottom: 20px;
  
  }
  
  /* form style */
  .uk-form-group {
    margin-bottom: 15px;
  }
  
  .uk-form-label {
    color: #3e416d;
    font-size: 15px;
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
  }
  
  .uk-position-relative {
    position: relative !important;
  }
  
  .uk-form-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #999;
    z-index: 3;
  }
  
  .uk-input{
    position: relative;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit;
    overflow: visible;
  }
  
  .uk-input[type="search"]::-webkit-search-cancel-button,
  .uk-input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  .uk-input[type="number"]::-webkit-inner-spin-button,
  .uk-input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  .uk-input::-moz-placeholder,
  .uk-textarea::-moz-placeholder {
    opacity: 1;
  }
  
  .uk-radio:not(:disabled),
  .uk-checkbox:not(:disabled) {
    cursor: pointer;
  }
  
  .uk-input,
  .uk-textarea {
    -webkit-appearance: none;
  }
  
  .uk-input{
    max-width: 100%;
    width: 100%;
    border: 0 none;
    /* padding: 0px 16px; */
    background: #fff !important;
    color: #666;
    border: 1px solid #eceff5;
    border-radius: 7px;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border;
  }
  
  .uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
    padding-left: 40px !important;
  }

  .uk-select{
    margin-bottom: 15px;
  }
  
  .uk-input{
    height: 40px;
    vertical-align: middle;
    display: inline-block;
  }
  
  .uk-input:not(input) {
    line-height: 38px;
  }
  .uk-input:hover {
    outline: none;
    background-color: #fff;
    color: #666;
    border-color: #6a6b77 !important;
    
  }
  
  /* Focus */
  .uk-input:focus {
    outline: none;
    background-color: #fff;
    color: #666;
    border-color: #6a6b77 !important;
    
  }
  
  /* Disabled */
  .uk-input:disabled {
    background-color: #f8f8f8;
    color: #999;
    border-color: #e5e5e5;
  }
  
  .uk-input::-ms-input-placeholder {
    color: #999 !important;
  }
  
  .uk-input::placeholder {
    color: #999;
  }
  
  .uk-form-small:not(textarea):not([multiple]):not([size]) {
    height: 37px;
    padding: 0px 11px;
  }
  
  .uk-form-small:not(select):not(input):not(textarea) {
    line-height: 28px;
  }
  .uk-form-label {
    color: #3e416d;
    font-size: 15px;
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
  }
  
  .uk-form-group {
    margin-bottom: 15px;
  }
  
  .uk-form-stacked .uk-form-label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Tablet portrait and smaller */
  @media (max-width: 959px) {
  
    /* Behave like `uk-form-stacked` */
    .uk-form-horizontal .uk-form-label {
      display: block;
      margin-bottom: 5px;
    }
  }
  
  /* Tablet landscape and bigger */
  @media (min-width: 960px) {
    .uk-form-horizontal .uk-form-label {
      width: 200px;
      margin-top: 7px;
      float: left;
    }
  
    .uk-form-horizontal .uk-form-controls {
      margin-left: 215px;
    }
  
    /* Better vertical alignment if controls are checkboxes and radio buttons with text */
    .uk-form-horizontal .uk-form-controls-text {
      padding-top: 7px;
    }
  }
  
  .uk-form-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  
  .uk-form-icon:hover {
    color: #666;
  }
  
  .uk-form-icon:not(a):not(button):not(input) {
    pointer-events: none;
  }
  
  .uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
    padding-left: 40px !important;
  }
  
  .uk-form-icon-flip {
    right: 5px;
    left: auto;
  }
  
  .uk-form-icon-flip~.uk-input {
    padding-right: 40px !important;
  }
  
  .uk-button-primary {
    background-color: #1e87f0;
    color: #fff;
    border: 1px solid transparent;
  }
  
  /* Hover + Focus */
  .uk-button-primary:hover,
  .uk-button-primary:focus {
    background-color: #0f7ae5;
    color: #fff;
  }
  
  /* OnClick + Active */
  .uk-button-primary:active,
  .uk-button-primary.uk-active {
    background-color: #0e6dcd;
    color: #fff;
  }
  
  /* Danger */
  .uk-button-danger {
    background-color: #f0506e !important;
    color: #fff;
    border: 1px solid transparent;
    outline: none !important;
  }
  
  /* Hover + Focus */
  .uk-button-danger:hover,
  .uk-button-danger:focus {
    background-color: #ee395b !important;
    color: #fff;
    outline: none;
    border: 1px solid transparent;
  }
  
  /* OnClick + Active */
  .uk-button-danger:active,
  .uk-button-danger.uk-active {
    background-color: #ec2147;
    color: #fff;
  }
  
  .uk-button-secondary {
    background-color: #222;
    color: #fff;
    border: 1px solid transparent;
  }
  /* Hover + Focus */
  .uk-button-secondary:hover,
  .uk-button-secondary:focus {
    background-color: #151515;
    color: #fff;
  }
  /* OnClick + Active */
  .uk-button-secondary:active,
  .uk-button-secondary.uk-active {
    background-color: #080808;
    color: #fff;
  }
  .uk-button-primary:disabled,
  .uk-button-danger:disabled {
    background-color: transparent;
    color: #999;
    border-color: #e5e5e5;
  }
  .btn-block {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
  
  .bottom-link {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .bottom-link p {
    font-weight: 400;
    color: #666666;
  }
  
  @media (min-width: 640px) {
    .sign-up-page-wrapper {
      max-width: 800px !important;
      width: 50% !important;
    }
  }
  
  @media (max-width: 991px) {
    .sign-up-page-flex {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      align-items: normal;
    }
  
    .logo {
      width: 215px;
      padding: 0px;
    }
  
    .left-column, .right-column {
      width: 100%;
      height: auto;
    }
  
    .left-column {
      background: #181636 !important;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 20vh;
    }
  }

  /* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    padding: 3px 0px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}


#container-circles{
    bottom: 30% !important;
}